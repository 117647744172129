




import Vue from "vue";
import { ActionTypes } from "@/store/action-types";

export default Vue.extend({
  name: "App",
  created() {
    window.vm = this;
  },
  watch: {
    "$route.params.orgId": {
      handler(orgId?: string) {
        if (orgId) {
          this.$storeTyped.dispatch(ActionTypes.SETUP_ORGANIZATION, orgId);
        }
      },
      immediate: true
    },
    "$route.name": {
      handler() {
        this.setupPageTitle();
      },
      immediate: true
    },
    "$store.state.organization.organization.name": {
      handler() {
        this.setupPageTitle();
      }
    }
  },
  methods: {
    setupPageTitle() {
      const route = this.$route;
      const name = this.$storeTyped.state?.organization?.organization.name;
      if (route.name === "Switch organization") {
        document.title = "Stent - Choose an Organization";
      } else if (route.name === "Edit profile") {
        document.title = `Edit your profile`;
      } else if (name) {
        document.title = `${name} - ${route.name}`;
      } else {
        document.title = `Stent Admin`;
      }
    }
  }
});
