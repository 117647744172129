import { AdminUser } from "./models/user";
import axios from "axios";

type DecodedJwtToken = {
  sub: string;
  aud: string;
  given_name: string;
  family_name: string;
  picture: string;
  name: string;
  email: string;
  token_usage: string;
  jti: string;
  cfd_lvl: string;
  scope: string[];
  azp: string;
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
};

const getCookie = (name: string) => {
  const cookie = document.cookie
    .split("; ")
    .find(row => row.trim().startsWith(name));
  return cookie ? cookie.split("=")[1] : null;
};

export function deleteCookie(cookieName: string) {
  const hostname = window.location.hostname
    .split(".")
    .map((v, i) => (i === 0 ? "" : v))
    .join(".");
  document.cookie = `${cookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${hostname}`;
}

export function parseJwt(token: string): DecodedJwtToken {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function getAuthToken() {
  const cookieName = process.env.VUE_APP_AUTH_COOKIE;
  if (!cookieName) {
    throw new Error(
      `Authentification error: Authentification cookie name must be set in ${process.env.NODE_ENV} environment`
    );
  }
  const token = getCookie(cookieName);
  return token ? `Bearer ${token}` : null;
}

export function getLoggedUser(): AdminUser | null {
  const token = getAuthToken();

  if (!token) {
    return null;
  }

  const { name, picture, azp, email } = parseJwt(token);
  return { name, picture, id: azp, email };
}

export function getRefreshToken() {
  const cookieName = process.env.VUE_APP_REFRESH_COOKIE;
  if (!cookieName) {
    throw new Error(
      `Refresh cookie name must be set in ${process.env.NODE_ENV} environment`
    );
  }
  const token = getCookie(cookieName);
  return token ? token : null;
}

export function isBearerExpired() {
  const bearer = getAuthToken();

  if (!bearer) {
    return true;
  }

  const decodedBearer = parseJwt(bearer);
  const expireTimestamp = decodedBearer.exp ? decodedBearer.exp * 1000 : 0;
  return new Date().getTime() > expireTimestamp;
}

export function cleanCookiesAndLocalStorage() {
  for (const cookieName of [
    process.env.VUE_APP_AUTH_COOKIE,
    process.env.VUE_APP_REFRESH_COOKIE
  ]) {
    if (cookieName) {
      localStorage.removeItem(cookieName);
      deleteCookie(cookieName);
    }
  }
}

export function redirectToAuthPage() {
  if (!process.env.VUE_APP_LOGIN_URL) {
    throw new Error(
      "Authentification error: Cannot redirect to authentification page while VUE_APP_LOGIN_URL env variable is not set"
    );
  }
  window.location.href = `${process.env.VUE_APP_LOGIN_URL}`;
}

/**
 * Try to get a new auth token from refresh token in session
 */
export async function refreshToken() {
  if (!process.env.VUE_APP_LOGIN_URL || !process.env.VUE_APP_CLIENT_ID) {
    throw new Error(
      "Authentification error: Cannot refresh token while VUE_APP_LOGIN_URL env variable is not set"
    );
  }
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    const body = {
      client_id: process.env.VUE_APP_CLIENT_ID,
      refresh_token: refreshToken
    };
    return await axios.post(
      "https://auth.stent.io/passwordless/refresh",
      // `http://admin.stent.io:8080/passwordless/refresh/passwordless/refresh`,
      body
    );
  } else {
    return null;
  }
}
